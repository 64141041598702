import React, { useEffect, useState } from "react";
import { Steps, useNavigatorModel } from "@/shared/model";
import styles from './NavBar.module.scss';
import { leadersModel } from "@/entities/leaders/model";
import { earnModel } from "@/entities/earn/model";
import { IonIcon } from '@ionic/react';
import { 
    homeOutline, 
    trophyOutline, 
    peopleOutline, 
    cashOutline, 
    walletOutline, 
    refreshOutline
} from 'ionicons/icons';
import { useConnectTon } from "@/features/ton/useConnectTon";

export const NavBar = () => {
    const { step, stepChanged } = useNavigatorModel();
    const [activeIndex, setActiveIndex] = useState(0);  
    const [isLoading, setIsLoading] = useState(false); 

    const { initialize } = useConnectTon(); 

    useEffect(() => {   

        switch (step) {
            case Steps.HOME:
                setActiveIndex(0);
                break;
            case Steps.BOARD:
                setActiveIndex(1);
                break;
            case Steps.FRENS:
                setActiveIndex(2);
                break;
            case Steps.EARN:
                setActiveIndex(3);
                break;
            default:
                setActiveIndex(0);
                break;
        }
    }, [step]);

    const handleItemClick = (index: number, page: Steps) => {
        setActiveIndex(index);  
        stepChanged(page);    
    };

    const handleWalletClick = async () => {
        setIsLoading(true);
        try {
            await initialize(); 
        } catch (error) {
            
            alert("Failed to connect wallet. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.navigation}>
            <ul>
                <li 
                    className={`${styles.list_item} ${activeIndex === 0 ? styles.active : ''}`} 
                    onClick={() => handleItemClick(0, Steps.HOME)}
                >
                    <a href="#">
                        <span className={styles.icon}>
                            <IonIcon icon={homeOutline} />
                        </span>
                        <span className={styles.text}>Home</span>
                    </a>
                </li>
                <li 
                    className={`${styles.list_item} ${activeIndex === 1 ? styles.active : ''}`} 
                    onClick={() => {
                        leadersModel.leadersRequested(); 
                        handleItemClick(1, Steps.BOARD);
                    }}
                >
                    <a href="#">
                        <span className={styles.icon}>
                            <IonIcon icon={trophyOutline} />
                        </span>
                        <span className={styles.text}>Leaders</span>
                    </a>
                </li>
                <li 
                    className={`${styles.list_item} ${activeIndex === 2 ? styles.active : ''}`} 
                    onClick={() => handleItemClick(2, Steps.FRENS)}
                >
                    <a href="#">
                        <span className={styles.icon}>
                            <IonIcon icon={peopleOutline} />
                        </span>
                        <span className={styles.text}>Frens</span>
                    </a>
                </li>
                <li 
                    className={`${styles.list_item} ${activeIndex === 3 ? styles.active : ''}`} 
                    onClick={() => {
                        earnModel.tasksRequested();
                        handleItemClick(3, Steps.EARN);
                    }}
                >
                    <a href="#">
                        <span className={styles.icon}>
                            <IonIcon icon={cashOutline} />
                        </span>
                        <span className={styles.text}>Earn</span>
                    </a>
                </li>
                {/* Wallet Button */}
                <li 
                    className={`${styles.list_item} ${isLoading ? styles.disabled : ''}`} 
                    onClick={isLoading ? undefined : handleWalletClick}
                >
                    <a href="#">
                        <span className={styles.icon}>
                            {isLoading ? (
                                <IonIcon icon={refreshOutline} className={styles.spinner} />
                            ) : (
                                <IonIcon icon={walletOutline} />
                            )}
                        </span>
                        <span className={styles.text}>Wallet</span>
                    </a>
                </li>
                <div 
                    className={styles.indicator} 
                    style={{ transform: `translateX(calc(70px * ${activeIndex} + ${activeIndex * 7}px))` }}
                ></div>
            </ul>
        </div>
    );
};