import React, { useState, useEffect } from 'react';
import { reflect } from '@effector/reflect';
import { earnModel } from '@/entities/earn/model';
import { LoaderTemplate } from '@/shared/ui/LoaderTemplate';
import styles from './Earn.module.scss';
import { EarnItem } from '@/entities/earn/model/types';
import Boost from './Boost';
import { useNavigatorModel, EarnTabs } from "@/shared/model";
import { TaskExpandModal } from '@/widgets/TaskExpandModal/TaskExpandModal';

const BoostReflect = reflect({
    view: Boost,
    bind: {},
});

export const Earn = () => {
    return (
        <div
            className={styles.root}
            style={{
                background:
                    'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)',
                height: '100vh',
                overflowY: 'auto',
            }}
        >
            <Title />
            <MainReflect />
        </div>
    );
};

const Title = () => (
    <h2
        className="text-4xl text-center mt-4 font-orbitron"
        style={{
            background: 'linear-gradient(115deg, #62cff4, #2c67f2)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
        }}
    >
        EARN
    </h2>
);

const Main = React.memo<{
    isLoading: boolean;
    list: EarnItem[];
    onTaskClick: (item: EarnItem) => void;
    activeTask: EarnItem | null; 
    onCloseTask: () => void;
}>(({ isLoading, list, onTaskClick, activeTask, onCloseTask }) => { // Pass activeTask and onCloseTask props here
    const { earnTab } = useNavigatorModel(); // Get the current active tab from the navigation model
    const [activeTab, setActiveTab] = useState<'partnerTasks' | 'boost'>(
        'partnerTasks'
    );

    // Update activeTab based on the navigation state
    useEffect(() => {
        if (earnTab === EarnTabs.BOOST) {
            setActiveTab('boost');
        } else {
            setActiveTab('partnerTasks');
        }
    }, [earnTab]);

    return (
        <>
            <LoaderTemplate className={styles.main} isLoading={isLoading}>
                <div className="text-white min-h-screen p-4">
                    {/* Tabs at the top */}
                    <div className="flex justify-center items-center mb-4 space-x-2">
                        <button
                            className={`${
                                activeTab === 'partnerTasks'
                                    ? 'bg-gradient-to-r from-[#62cff4] to-[#2c67f2]'
                                    : 'bg-gray-700'
                            } text-sm font-medium px-4 py-2 rounded-full focus:outline-none`}
                            onClick={() => setActiveTab('partnerTasks')}
                            style={{
                                background: activeTab === 'partnerTasks'
                                    ? 'linear-gradient(115deg, #62cff4, #2c67f2)'
                                    : undefined,
                            }}
                        >
                            🤝 Partner Tasks
                        </button>
                        <button
                            className={`${
                                activeTab === 'boost'
                                    ? 'bg-gradient-to-r from-[#62cff4] to-[#2c67f2]'
                                    : 'bg-gray-700'
                            } text-sm font-medium px-4 py-2 rounded-full focus:outline-none`}
                            onClick={() => setActiveTab('boost')}
                            style={{
                                background: activeTab === 'boost'
                                    ? 'linear-gradient(115deg, #62cff4, #2c67f2)'
                                    : undefined,
                            }}
                        >
                            ⚡️ Boost
                        </button>
                    </div>

                    {activeTab === 'partnerTasks' && (
                        <TaskList list={list} onTaskClick={onTaskClick} />
                    )}
                    {activeTab === 'boost' && <BoostReflect />}
                </div>
            </LoaderTemplate>
            
            {activeTask && (
                <TaskExpandModal data={activeTask} onClose={onCloseTask} />
            )}
        </>
    );
});

const MainReflect = reflect({
    view: Main,
    bind: {
        isLoading: earnModel.$isLoading,
        list: earnModel.$list,
        onTaskClick: earnModel.taskSelected,
        activeTask: earnModel.$activeTask,
        onCloseTask: earnModel.taskClosed, 
    },
});

const TaskList = React.memo<{
  list: EarnItem[];
  onTaskClick: (item: EarnItem) => void;
}>(({ list, onTaskClick }) => (
  <div className="space-y-4">
    {list.map((item) => (
      <TaskItem key={item.id} item={item} onTaskClick={onTaskClick} />
    ))}
  </div>
));

const TaskItem = React.memo<{
  item: EarnItem;
  onTaskClick: (item: EarnItem) => void;
}>(({ item, onTaskClick }) => {
  const isDone = item.isDone === 'done';

  const handleClick = () => {
    if (!isDone) {
      onTaskClick(item);
    }
  };

  return (
    <div
      className={`bg-gray-900 p-3 rounded-lg flex justify-between items-center ${
        isDone ? 'opacity-70' : ''
      }`}
      onClick={handleClick}
    >
      <div className="flex items-center space-x-3">
        <img
          src={item.avatar}
          alt={item.name}
          className="w-8 h-8 rounded-full"
        />
        <div>
          <h3 className="text-lg font-semibold font-orbitron text-white">
            {item.name}
          </h3>
          <p
            className="text-sm font-orbitron"
            style={{
              background: 'linear-gradient(115deg, #62cff4, #2c67f2)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
            }}
          >
            +{item.amount}
          </p>
        </div>
      </div>
      <div>
        {isDone ? (
          <svg
            className="w-5 h-5 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        ) : (
          <svg
            className="w-4 h-4 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        )}
      </div>
    </div>
  );
});
