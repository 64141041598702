import { EarnApi, GetEarnDataResponse, GetEarnDataResponseItem, ResponseDefault, BoostItem, SkinItem } from './types';
import { $sessionId } from "@/shared/model/session";

// Helper function to handle API responses
async function handleResponse<T>(response: Response): Promise<ResponseDefault<T>> {
  if (!response.ok) {
    const errorText = await response.text(); // Capture the error response
    return { error: true, payload: null };
  }

  try {
    const data: T = await response.json();
    return { error: false, payload: data };
  } catch (error) {
    return { error: true, payload: null };
  }
}

function getSessionId(): string | null {
  return $sessionId.getState();  
}

async function fetchData<T>(url: string): Promise<ResponseDefault<T>> {
  const sessionId = getSessionId();  

  if (!sessionId) {
    
    return { error: true, payload: null };
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Session ${sessionId}`,
      },
    });

    return await handleResponse<T>(response); 
  } catch (error) {
    
    return { error: true, payload: null };
  }
}

async function postData<T>(url: string, body: any): Promise<ResponseDefault<T>> {
  const sessionId = getSessionId(); 

  if (!sessionId) {
    
    return { error: true, payload: null };
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Session ${sessionId}`,
      },
      body: JSON.stringify(body),
    });

    return await handleResponse<T>(response);
  } catch (error) {
   
    return { error: true, payload: null };
  }
}

export const earnApi: EarnApi = {
  getData: async () => {
    const response = await fetchData<{
      tasks: GetEarnDataResponseItem[];
      boosts: BoostItem[];
      skins: SkinItem[];
      user_level: number;
    }>('/api/earn/task.php');


    if (response.error || !response.payload) {
      console.warn("Earn data fetched but might be missing or empty.");
    }

    return response as GetEarnDataResponse; 
  },

  taskJoined: async (data: { id: number; reward: string }) => {
   
    const response = await postData('/api/earn/complete_task.php', {
      task_id: data.id,
      reward: data.reward,
      status: 'completed',
    });


    return response;
  },

  purchaseBoost: async (data: { boost_id: number }) => {
    const response = await postData('/api/earn/purchase_boost.php', data);
    return response;
  },

  purchaseSkin: async (data: { skin_id: number }) => {
    const response = await postData('/api/earn/purchase_skin.php', data);
    return response;
  },
};