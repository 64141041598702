import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { clickerModel } from "@/features/clicker/model";
import { useUnit } from 'effector-react';
import { $sessionId } from "@/shared/model/session";

const PointContext = createContext<any>(null);

export const usePoints = () => useContext(PointContext);

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [points, setPoints] = useState<number>(0);
  const sessionId = useUnit($sessionId); // Get session ID from the session store
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  // Function to update points to the backend
  const updatePoint = async () => {
    try {
      const response = await fetch('/api/game/update_points.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ session_id: sessionId, points }),
      });

      const result = await response.json();
      // You can handle the result here if needed

    } catch (error) {
      console.error("Error updating points:", error);
    }
  };

  // Debounce function to delay updating points
  const debounceUpdatePoint = useCallback(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(() => {
      updatePoint();
    }, 30000); // 30 seconds debounce

    setDebounceTimer(timer);
  }, [debounceTimer, sessionId, points]);

  // Handle disconnection or tab close
  useEffect(() => {
    const handleBeforeUnload = () => {
      updatePoint(); // Trigger point update on tab close
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [points, sessionId]);

  const incrementPoints = async (newPoints: number) => {
    if (!sessionId || newPoints === undefined) {
      console.error('Invalid session or points');
      return;
    }

    try {
      const response = await fetch('/api/game/increment_points.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ session_id: sessionId, points: newPoints }),
      });

      const result = await response.json();
      // You can handle the result here if needed

    } catch (error) {
      console.error("Error incrementing points:", error);
    }
  };

  return (
    <PointContext.Provider value={{ points, incrementPoints }}>
      {children}
    </PointContext.Provider>
  );
};