import { createEvent, createStore } from 'effector';
import { useUnit } from 'effector-react';

export const setRank = createEvent<string>();

export const $rank = createStore<string>("No Rank")
  .on(setRank, (_, rank) => rank);

export const useRang = () => useUnit($rank);

export const randModel = {
  setRank,
  $rank,
  useRang,
};