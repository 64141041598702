import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { earnModel } from '@/entities/earn/model';
import { BoostItem, SkinItem } from '@/entities/earn/model/types';
import { clickerModel } from '@/features/clicker/model';

const Boost = () => {
  const boosts = useUnit(earnModel.$boosts);
  const skins = useUnit(earnModel.$skins);
  const onBoostPurchase = earnModel.boostPurchased;
  const onSkinPurchase = earnModel.skinPurchased;
  const { totalEarnings, currentScore } = useUnit({
    totalEarnings: clickerModel.$totalEarnings,
    currentScore: clickerModel.$value,
  });

  // Notification State
  const [notification, setNotification] = useState<{
    message: string;
    visible: boolean;
  }>({ message: '', visible: false });

  // Modal State for Skin
  const [selectedSkin, setSelectedSkin] = useState<SkinItem | null>(null);

  // Handle Boost Purchase
  const handleBoostPurchase = async (item: BoostItem) => {
  try {
    await onBoostPurchase(item); // Await backend update
    setNotification({
      message: `Successfully purchased ${item.name}!`,
      visible: true,
    });
    // Hide notification after 3 seconds
    setTimeout(() => setNotification({ message: '', visible: false }), 3000);
  } catch (error) {
    setNotification({
      message: `Failed to purchase ${item.name}. Please try again.`,
      visible: true,
    });
    setTimeout(() => setNotification({ message: '', visible: false }), 3000);
  }
};

  // Handle Skin Purchase
  const handleSkinPurchase = (item: SkinItem) => {
    if (!item.isPurchased) {
      setSelectedSkin(item);
    }
  };

  // Confirm Skin Purchase
  const confirmSkinPurchase = async () => {
  if (selectedSkin) {
    try {
      await onSkinPurchase(selectedSkin); // Await backend update
      setNotification({
        message: `${selectedSkin.name} skin purchased successfully!`,
        visible: true,
      });
      setSelectedSkin(null);
      setTimeout(() => setNotification({ message: '', visible: false }), 3000);
    } catch (error) {
      setNotification({
        message: `Failed to purchase ${selectedSkin.name}. Please try again.`,
        visible: true,
      });
      setTimeout(() => setNotification({ message: '', visible: false }), 3000);
    }
  }
};

  // Cancel Skin Purchase
  const cancelSkinPurchase = () => {
    setSelectedSkin(null);
  };

  return (
    <div className="relative p-4">
      {/* Success Notification */}
      {notification.visible && (
        <div className="fixed top-5 right-5 z-50">
          <div className="flex items-center justify-between w-80 h-16 rounded-lg bg-gradient-to-r from-[#62cff4] to-[#2c67f2] px-4 py-2 shadow-lg space-x-3">
            {/* Icon */}
            <div className="flex items-center space-x-3">
              <div className="bg-white/20 backdrop-blur-md p-2 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 text-[#2b9875]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 12.75 6 6 9-13.5"
                  />
                </svg>
              </div>
              {/* Message Text */}
              <div className="flex flex-col text-left">
                <p className="text-white text-sm font-semibold">Success</p>
                <p className="text-gray-100 text-xs">{notification.message}!</p>
              </div>
            </div>
            {/* Close Button */}
            <button
              className="text-gray-200 hover:bg-white/20 p-1 rounded-full transition-colors ease-in-out"
              onClick={() => setNotification({ message: '', visible: false })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Primary Boost Section */}
      <div className="space-y-3">
        {boosts.map((item) => (
          <BoostItemComponent
            key={item.id}
            item={item}
            onPurchase={handleBoostPurchase}
            currentScore={currentScore}
          />
        ))}
      </div>

      {/* Skin Section */}
      <h2 className="text-2xl font-semibold mt-6 mb-3 font-orbitron text-white">
        Upgrade
      </h2>
      <div className="space-y-3">
        {skins.map((item) => (
          <SkinItemComponent
            key={item.id}
            item={item}
            onPurchase={handleSkinPurchase}
            currentScore={currentScore}
            totalEarnings={totalEarnings}
          />
        ))}
      </div>

      {/* Skin Purchase Modal */}
      {selectedSkin && (
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
          <div className="text-white rounded-lg shadow-lg w-80 p-6 text-center" style={{ background: 'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)' }}>
            {/* Close Button */}
            <div className="flex justify-end">
              <button
                className="text-gray-400 hover:text-gray-200"
                onClick={cancelSkinPurchase}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"/>
                </svg>
              </button>
            </div>

            {/* Skin Image */}
            <div className="flex justify-center mb-4">
              <img src={selectedSkin.image_link} alt="Skin Image" className="w-32 h-32 rounded-full" />
            </div>

            {/* Title */}
            <h2 className="text-2xl font-semibold mb-2 text-white">{selectedSkin.name}</h2>

            {/* Description */}
            <p className="text-gray-300 text-sm mb-4">
              {selectedSkin.description}
            </p>

            {/* Details */}
            <div className="space-y-2 mb-6">
              <div className="flex items-center justify-center">
                <span className="text-yellow-500 mr-2">🪙</span>
                <span>
                  <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">Cost:</span>{' '}
                  <span className="text-white">
                    <strong>-{selectedSkin.cost} </strong>
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-green-500 mr-2">💰</span>
                <span>
                  <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent"> Min Points:</span>{' '}
                  <span className="text-white">
                    <strong>{selectedSkin.required_earnings}</strong>
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-blue-500 mr-2">🔋</span>
                <span>
                  <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">Energy:</span>{' '}
                  <span className="text-white">
                    <strong>+{selectedSkin.available}</strong>
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-red-500 mr-2">⚡️</span>
                <span>
                  <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">Damage:</span>{' '}
                  <span className="text-white">
                    <strong>+{selectedSkin.click_step}</strong>
                  </span>
                </span>
              </div>
              <div className="flex items-center justify-center">
                <span className="text-red-500 mr-2">📤</span>
                <span>
                  <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">Level:</span>{' '}
                  <span className="text-white">
                    <strong>{selectedSkin.level}</strong>
                  </span>
                </span>
              </div>
            </div>

            {/* Action Button */}
            <button
              className={`w-full py-2 px-4 rounded-full transition duration-300 ${
                selectedSkin.isPurchased
                  ? 'bg-gray-500 cursor-not-allowed'
                  : 'bg-gradient-to-r from-[#62cff4] to-[#2c67f2]'
              }`}
              onClick={confirmSkinPurchase}
              disabled={selectedSkin.isPurchased}
            >
              {selectedSkin.isPurchased ? 'Owned' : 'Purchase'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const BoostItemComponent = React.memo<{
  item: BoostItem;
  onPurchase: (item: BoostItem) => void;
  currentScore: number;
}>(({ item, onPurchase, currentScore }) => {
  const canPurchase = currentScore >= item.cost;

  return (
    <div
      className={`bg-gray-800 p-3 rounded-lg flex justify-between items-center ${
        !canPurchase ? 'opacity-50' : ''
      }`}
    >
      <div className="flex items-center space-x-3">
        <img
          src={item.image_link}
          alt={item.name}
          className="w-8 h-8 rounded-full"
        />
        <div>
          <h3 className="text-lg font-semibold font-orbitron text-white bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
            {item.name}
          </h3>
          <p className="text-sm text-gray-300 font-orbitron">
            <span className="text-white">cost:</span>{' '}
            <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
              -{item.cost}
            </span>
          </p>
          <p className="text-sm text-gray-300 font-orbitron">
            <span className="text-white">effect:</span>{' '}
            <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
              +{item.effect}
            </span>
          </p>
        </div>
      </div>
      <button
        className={`${
          canPurchase
            ? 'bg-gradient-to-r from-[#62cff4] to-[#2c67f2]'
            : 'bg-gray-500 cursor-not-allowed'
        } text-white px-3 py-1 rounded-full font-orbitron text-sm`}
        onClick={() => canPurchase && onPurchase(item)}
        disabled={!canPurchase}
      >
        Purchase
      </button>
    </div>
  );
});

const SkinItemComponent = React.memo<{
  item: SkinItem;
  onPurchase: (item: SkinItem) => void;
  currentScore: number;
  totalEarnings: number;
}>(({ item, onPurchase, currentScore, totalEarnings }) => {
  const isAffordable = currentScore >= item.cost;
  const hasRequiredEarnings = totalEarnings >= item.required_earnings;

  const canPurchase = isAffordable && hasRequiredEarnings && !item.isPurchased;

  return (
    <div
      className={`bg-gray-800 p-3 rounded-lg flex justify-between items-center ${
        !canPurchase ? 'opacity-50' : ''
      }`}
    >
      <div className="flex items-center space-x-3">
        <img
          src={item.image_link}
          alt={item.name}
          className="w-8 h-8 rounded-full"
        />
        <div>
          <h3 className="text-lg font-semibold font-orbitron text-white bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
            {item.name}
          </h3>
          <p className="text-sm text-gray-300 font-orbitron">
            <span className="text-white">cost:</span>{' '}
            <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
              -{item.cost}
            </span>
          </p>
          <p className="text-sm text-gray-300 font-orbitron">
            <span className="text-white">points:</span>{' '}
            <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
              +{item.required_earnings}
            </span>
          </p>       
          {item.isPurchased && (
            <p className="text-green-500 text-sm mt-1">Purchased</p>
          )}
        </div>
      </div>
      <button
        className={`${
          canPurchase
            ? 'bg-gradient-to-r from-[#62cff4] to-[#2c67f2]'
            : 'bg-gray-500 cursor-not-allowed'
        } text-white px-3 py-1 rounded-full font-orbitron text-sm`}
        onClick={() => canPurchase && onPurchase(item)}
        disabled={!canPurchase}
      >
        {item.isPurchased ? 'Owned' : 'Purchase'}
      </button>
    </div>
  );
});

export default Boost;