import { useCallback, useEffect, useRef } from "react";
import {
  Account,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { TonProofItemReplySuccess } from "@tonconnect/sdk";
import { createRequest } from "@/shared/lib/api/createRequest";
import { walletModel } from "@/shared/model/wallet";
import { useAuth } from "@/features/auth/useAuth";

interface CheckProofPayload {
  message: string;
}

interface StoreWalletPayload {
  message: string;
}

interface StoreWalletResponse {
  error: boolean;
  message: string;
}

export const useConnectTon = () => {
  const firstProofLoading = useRef<boolean>(true);
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const { updateWallet } = walletModel.useWalletModel();
  const authModel = useAuth();

  const createProofPayload = useCallback(async (): Promise<void> => {
    console.log("[useConnectTon] Starting createProofPayload.");
    try {
      if (firstProofLoading.current) {
        console.log(
          "[useConnectTon] Setting connect request parameters to 'loading'."
        );
        tonConnectUI.setConnectRequestParameters({ state: "loading" });
        firstProofLoading.current = false;
      }

      const response = await createRequest<string>({
        endpoint: "/api/wallet/generatePayload.php",
        method: "POST",
        body: {
          sessionId: authModel.sessionId,
        },
      });

      console.log(
        "[useConnectTon] Received response from generatePayload.php:",
        response
      );

      if (response.error) {
        console.error(
          "[useConnectTon] Error generating payload:",
          response.message
        );
        tonConnectUI.setConnectRequestParameters(null);
        return;
      }

      if (response.payload) {
        console.log(
          "[useConnectTon] Setting connect request parameters to 'ready' with tonProof:",
          response.payload
        );
        tonConnectUI.setConnectRequestParameters({
          state: "ready",
          value: {
            tonProof: response.payload, // The challenge
          },
        });
      } else {
        console.warn(
          "[useConnectTon] No payload received. Resetting connect request parameters."
        );
        tonConnectUI.setConnectRequestParameters(null);
      }
    } catch (e) {
      console.error("[useConnectTon] Exception in createProofPayload:", e);
      tonConnectUI.setConnectRequestParameters(null);
    }
  }, [tonConnectUI, authModel.sessionId]);

  const storeWallet = useCallback(
    async (walletAddress: string): Promise<void> => {
      console.log("[useConnectTon] Storing wallet address:", walletAddress);
      const storeResponse = await createRequest<StoreWalletPayload>({
        endpoint: "/api/wallet/storeWallet.php",
        method: "POST",
        body: {
          sessionId: authModel.sessionId,
          walletAddress,
        },
      });

      console.log(
        "[useConnectTon] Received response from storeWallet.php:",
        storeResponse
      );

      if (storeResponse.error) {
        console.error(
          "[useConnectTon] Failed to store wallet address:",
          storeResponse.message
        );
      } else {
        console.log("[useConnectTon] Wallet address stored successfully.");
      }
    },
    [authModel.sessionId]
  );

  const checkProof = useCallback(
    async (
      tonProofItem: TonProofItemReplySuccess,
      account: Account
    ): Promise<void> => {
      console.log(
        "[useConnectTon] Entering checkProof with proof and account details:",
        tonProofItem,
        account
      );

      try {
        const { payload, signature } = tonProofItem.proof;

        const checkResponse = await createRequest<CheckProofPayload>({
          endpoint: "/api/wallet/checkProof.php",
          method: "POST",
          body: {
            sessionId: authModel.sessionId,
            proof: {
              payload,
              signature,
            },
            publicKey: account.publicKey,
          },
        });

        console.log(
          "[useConnectTon] Received response from checkProof.php:",
          checkResponse
        );

        if (checkResponse.error) {
          console.error(
            "[useConnectTon] Proof validation failed:",
            checkResponse.message
          );
          await tonConnectUI.disconnect();
        } else {
          console.log(
            "[useConnectTon] Proof validated successfully. Storing wallet address."
          );
          updateWallet(account.address);
          await storeWallet(account.address);
        }
      } catch (error) {
        console.error("[useConnectTon] Error validating proof:", error);
      }
    },
    [tonConnectUI, updateWallet, authModel.sessionId, storeWallet]
  );

  useEffect(() => {
    console.log("[useConnectTon] Setting up status change listener.");
    tonConnectUI.onStatusChange(async (v) => {
      if (!v) {
        console.warn("[useConnectTon] Status change event is null or undefined.");
        return;
      }

      console.log("[useConnectTon] Status changed:", v);

      if (!v.account) {
        console.log(
          "[useConnectTon] No account found (wallet disconnected). Reinitializing."
        );
        firstProofLoading.current = true;
        await initialize(); // Reinitialize connection if the wallet is disconnected
        return;
      }

      if (v.account && v.connectItems && v.connectItems.tonProof) {
        const tonProofItem = v.connectItems.tonProof as TonProofItemReplySuccess;

        if (tonProofItem && tonProofItem.proof) {
          console.log(
            "[useConnectTon] Valid account and tonProof found. Initiating proof check."
          );
          console.log("[useConnectTon] tonProof data:", tonProofItem.proof);

          await checkProof(tonProofItem, v.account);
        } else {
          console.error(
            "[useConnectTon] tonProof is missing required properties 'payload' or 'signature'."
          );
        }
      } else {
        console.warn(
          "[useConnectTon] Required proof data not found, skipping checkProof call."
        );
      }
    });
    // Since 'initialize' is a dependency and it's declared after this effect,
    // we can safely ignore it in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tonConnectUI, checkProof]);

  const initialize = useCallback(async (): Promise<void> => {
    console.log("[useConnectTon] Initializing wallet connection.");
    try {
      const walletCheckResponse = await createRequest<{ wallet: string }>({
        endpoint: "/api/wallet/getWallet.php",
        method: "POST",
        body: {
          sessionId: authModel.sessionId,
        },
      });

      console.log(
        "[useConnectTon] Received response from getWallet.php:",
        walletCheckResponse
      );

      // Check if there's an error in fetching the wallet
      if (walletCheckResponse.error) {
        console.error(
          "[useConnectTon] Error fetching wallet:",
          walletCheckResponse.message
        );
      }
      // Check if a wallet is already connected
      else if (
        walletCheckResponse.payload &&
        walletCheckResponse.payload.wallet &&
        walletCheckResponse.payload.wallet !== "none"
      ) {
        console.log(
          "[useConnectTon] Wallet already connected:",
          walletCheckResponse.payload.wallet
        );
        updateWallet(walletCheckResponse.payload.wallet);
        return; // Stop further initialization as wallet is already connected
      }

      // Proceed with wallet connection if no wallet is connected
      if (!wallet || firstProofLoading.current) {
        console.log(
          "[useConnectTon] Creating proof payload as no wallet or reinitialization required."
        );
        await createProofPayload();
      }

      // Open the Ton Connect modal if the wallet is not connected
      if (!wallet) {
        console.log(
          "[useConnectTon] Wallet not connected. Attempting to open Ton Connect modal."
        );
        await Promise.race([
          tonConnectUI.openModal().then(() => {
            console.log(
              "[useConnectTon] Ton Connect modal opened successfully."
            );
          }),
          new Promise((_, reject) =>
            setTimeout(() => reject("Timeout while opening modal"), 30000)
          ),
        ]);
      } else {
        console.log("[useConnectTon] Wallet already connected:", wallet);
      }
    } catch (e) {
      console.error("[useConnectTon] Exception in initialize:", e);
    }
  }, [
    tonConnectUI,
    wallet,
    createProofPayload,
    authModel.sessionId,
    updateWallet,
  ]);

  useEffect(() => {
    if (wallet && firstProofLoading.current) {
      console.log("[useConnectTon] Wallet detected on mount. Calling initialize().");
      initialize();
    } else {
      console.log("[useConnectTon] No wallet detected on mount.");
    }
  }, [wallet, initialize]);

  return {
    initialize,
  };
};