import { EarnItem, BoostItem, SkinItem } from './types';
import { earnApi } from '@/shared/api/earn';
import { createEvent, createStore, sample, createEffect } from 'effector';
import { GetEarnDataResponse, GetEarnDataResponseItem } from '@/shared/api/earn/types';
import { TelegramWindow } from "@/shared/lib/hooks/useTelegram";
import { clickerModel } from "@/features/clicker/model";
import { randModel } from "@/shared/model/rang";

function toDomainTasks(data: GetEarnDataResponse): EarnItem[] {
  return data.payload ? data.payload.tasks.map((item: GetEarnDataResponseItem) => {
    return {
      id: item.id,
      avatar: item.image_link,
      name: item.name,
      amount: `${item.reward} ${item.reward_symbol}`, // Directly use item.reward here
      description: item.description,
      time: item.end_time,
      tasks: item.task_list,
      link: item.link,
      isDone: item.isDone || 'pending'
    };
  }) : [];
}

// Effect to fetch data and tasks
const fetchFx = createEffect(async (): Promise<GetEarnDataResponse> => {
  const earnData = await earnApi.getData();
  if (earnData.error || !earnData.payload) {
    throw new Error("Failed to fetch tasks or tasks are not available");
  }
  return earnData;
});

// Stores for the list of tasks, boosts, and skins
const $list = createStore<EarnItem[]>([]);
const $boosts = createStore<BoostItem[]>([]);
const $skins = createStore<SkinItem[]>([]);

// Store and events for task management
const $activeTask = createStore<EarnItem | null>(null);
const $collabs = $list.map(item => item.length);
const $isLoading = fetchFx.pending;

// Event to request tasks
const tasksRequested = createEvent();

// Sample for fetching data when tasks are requested
sample({
  clock: tasksRequested,
  target: fetchFx,
});

// Update $list store
sample({
  clock: fetchFx.doneData,
  fn: (data) => toDomainTasks(data),
  target: $list,
});

// Update $boosts store
sample({
  clock: fetchFx.doneData,
  fn: (data) => data.payload!.boosts as BoostItem[],
  target: $boosts,
});

// Update $skins store
sample({
  clock: fetchFx.doneData,
  fn: (data) => data.payload!.skins as SkinItem[],
  target: $skins,
});

// Sample logic for task selection and task closing
const taskSelected = createEvent<EarnItem>();
const taskClosed = createEvent();
const taskJoined = createEvent<EarnItem>();

sample({
  clock: taskSelected,
  target: $activeTask,
});

sample({
  clock: taskClosed,
  fn: () => null,
  target: $activeTask,
});

// Event to update the task list
const tasksUpdated = createEvent<EarnItem[]>();
$list.on(tasksUpdated, (_, updatedTasks) => updatedTasks);

// Event and effect for joining a task
const taskJoinedFx = createEffect(async (data: { id: number, link: string, amount: string }) => {
  const tg = (window as unknown as TelegramWindow).Telegram.WebApp;
  const task = $list.getState().find(t => t.id === data.id);

  if (!task) throw new Error('Task not found');

  const reward = parseInt(data.amount.split(' ')[0]);

  const response = await earnApi.taskJoined({ id: data.id, reward: reward.toString() });

  const updatedTasks = $list.getState().map(t =>
    t.id === data.id ? { ...t, isDone: 'done' } : t
  );
  tasksUpdated(updatedTasks);

  const newScore = clickerModel.$value.getState() + reward;
  const newTotalEarnings = clickerModel.$totalEarnings.getState() + reward;
  clickerModel.clicked({
    score: newScore,
    click_score: reward,
    available_clicks: clickerModel.$available.getState(),
    total_earnings: newTotalEarnings,
  });
  clickerModel.totalEarningsUpdated(newTotalEarnings);

  // Open the task link
  tg.openLink(data.link);
});

sample({
  clock: taskJoined,
  filter: (task) => task.isDone !== 'done',
  target: taskJoinedFx,
});

// Events for purchases
const boostPurchased = createEvent<BoostItem>();
const skinPurchased = createEvent<SkinItem>();

// Effects for backend updates
const purchaseBoostFx = createEffect(async (item: BoostItem) => {
  const response = await earnApi.purchaseBoost({ boost_id: item.id });
  if (response.error || !response.payload) {
    throw new Error('Failed to purchase boost');
  }
  return response.payload;
});

const purchaseSkinFx = createEffect(async (item: { skin_id: number }) => {
  const response = await earnApi.purchaseSkin(item);
  if (response.error || !response.payload) {
    throw new Error('Failed to purchase skin');
  }
  return response.payload;
});

// Update stores
// For Boost Purchases
sample({
  clock: boostPurchased,
  target: purchaseBoostFx,
});

// For Skin Purchases
sample({
  clock: skinPurchased,
  fn: (skin: SkinItem) => ({ skin_id: skin.id }),
  target: purchaseSkinFx,
});

// Update boosts store after purchase
sample({
  clock: purchaseBoostFx.doneData,
  fn: (data) => {
    const currentBoosts = $boosts.getState();
    const updatedBoosts = currentBoosts.map(boost => {
      if (boost.id === data.boost_id) {
        return {
          ...boost,
          cost: data.newCost,
          effect: data.newEffect,
        };
      }
      return boost;
    });
    return updatedBoosts;
  },
  target: $boosts,
});

// Update user stats after purchasing boost
sample({
  clock: purchaseBoostFx.doneData,
  fn: (data) => data.newAvailableClicks,
  target: clickerModel.availableUpdated,
});

sample({
  clock: purchaseBoostFx.doneData,
  fn: (data) => data.newClickStep,
  target: clickerModel.clickStepUpdated,
});

sample({
  clock: purchaseBoostFx.doneData,
  fn: (data) => data.newScore,
  target: clickerModel.valueInited,
});

// Update user stats after purchasing skin
sample({
  clock: purchaseSkinFx.done,
  fn: ({ params, result }) => {
    const currentSkins = $skins.getState();
    const updatedSkins = currentSkins.map(skin => {
      if (skin.id === params.skin_id) {
        return {
          ...skin,
          isPurchased: true,
        };
      }
      return skin;
    });
    return updatedSkins;
  },
  target: $skins,
});

sample({
  clock: purchaseSkinFx.done,
  fn: ({ result }) => result.newSkin,
  target: clickerModel.skinUpdated,
});

sample({
  clock: purchaseSkinFx.done,
  fn: ({ result }) => result.newAvailableClicks,
  target: clickerModel.availableUpdated,
});

sample({
  clock: purchaseSkinFx.done,
  fn: ({ result }) => result.newClickStep,
  target: clickerModel.clickStepUpdated,
});

sample({
  clock: purchaseSkinFx.done,
  fn: ({ result }) => result.newScore,
  target: clickerModel.valueInited,
});

sample({
  clock: purchaseSkinFx.done,
  fn: ({ result }) => result.newRank, 
  target: randModel.setRank, 
});

export const earnModel = {
  $list,
  $boosts,
  $skins,
  $activeTask,
  $collabs,
  $isLoading,
  tasksRequested,
  taskSelected,
  taskClosed,
  taskJoinedFx,
  boostPurchased,
  skinPurchased,
  taskJoined,
  purchaseBoostFx,
  purchaseSkinFx, 
};