import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import { useSessionId } from "@/shared/model/session";
import { walletModel } from "@/shared/model/wallet";
import { randModel, setRank } from "@/shared/model/rang";
import { useErrorHandler } from "@/shared/lib/hooks/useErrorHandler";
import { clickerModel } from "@/features/clicker/model"; // Ensure this import is present

// Import events from clickerModel
const { valueInited, availableInited, totalEarningsUpdated, skinUpdated, clickStepUpdated } = clickerModel;

const setSessionId = createEvent<string>();
const $sessionId = createStore<string | null>(null).on(setSessionId, (_, id) => id);

const setIsAuth = createEvent<boolean>();
const $isAuth = createStore(false).on(setIsAuth, (_, value) => value);

const setUserName = createEvent<string>();
const $userName = createStore<string | null>(null).on(setUserName, (_, name) => name);

export const useAuth = () => {
  const navigate = useNavigate();
  const isAuth = useUnit($isAuth);
  const sessionIdStore = useSessionId();
  const wallet = walletModel.useWalletModel();
  const rangModel = randModel.useRang();
  const { setError } = useErrorHandler();
  const sessionId = useUnit($sessionId);
  const userName = useUnit($userName);
  const clickStep = useUnit(clickerModel.$clickStep); // Access from clickerModel
  const skin = useUnit(clickerModel.$skin); // Access from clickerModel
  const rank = useUnit(randModel.$rank);

  const initialize = useCallback(async () => {
    console.log("Initializing authentication...");
    if (isAuth) {
      console.log("Already authenticated, exiting initialize.");
      return; 
    }

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const telegramData: any = {};

      // Extract Telegram login data from URL parameters
      urlParams.forEach((value, key) => {
        telegramData[key] = value;
      });

      if (!telegramData.hash) {
        console.error("No Telegram login data found in URL.");
        setError("No Telegram login data found");
        return;
      }

      console.log("Telegram data from URL:", telegramData);

      // Send the Telegram data to the backend for verification
      console.log("Sending authentication request to server...");
      const response = await fetch("/api/auth_handler.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(telegramData),
      });

      console.log(`Server responded with status: ${response.status}`);

      if (!response.ok) {
        console.error(`Authentication failed, server responded with status ${response.status}`);
        setError("Authentication failed, invalid response");
        sessionIdStore.remove();
        return;
      }

      const data = await response.json();
      console.log("Received data from server:", data);

      if (data.error) {
        throw new Error(data.message || "Unknown error from API");
      }

      // Extract session_id from payload
      if (data.payload.session_id) {
        console.log(`Setting session ID: ${data.payload.session_id}`);
        sessionIdStore.set(data.payload.session_id);
        setSessionId(data.payload.session_id);
      } else {
        throw new Error("Session ID not provided by API");
      }

      if (data.payload.username) {
        console.log(`Setting username: ${data.payload.username}`);
        setUserName(data.payload.username);
      } 

      if (data.payload.rank) {
        console.log(`Setting rank: ${data.payload.rank}`);
        setRank(data.payload.rank); 
      } 

      // Store initial game data globally
      console.log(`Initializing score: ${data.payload.score}`);
      valueInited(data.payload.score);

      console.log(`Initializing available clicks: ${data.payload.available_clicks}`);
      availableInited(data.payload.available_clicks);

      if (typeof data.payload.total_earnings === 'number') {
        console.log(`Updating total earnings: ${data.payload.total_earnings}`);
        totalEarningsUpdated(data.payload.total_earnings);
      }

      if (data.payload.wallet !== undefined) {
        console.log(`Updating wallet: ${data.payload.wallet}`);
        wallet.updateWallet(data.payload.wallet);
      } 

      if (typeof data.payload.click_step === 'number') {
        console.log(`Updating click step: ${data.payload.click_step}`);
        clickStepUpdated(data.payload.click_step); // Update globally
      } 

      if (data.payload.skin) {
        console.log(`Updating skin: ${data.payload.skin}`);
        skinUpdated(data.payload.skin); // Update globally
      } 

      setIsAuth(true);

      console.log("Authentication successful, navigating to /main");
      navigate("/main");
    } catch (e) {
      console.error("Error during initialization:", e);
      setError(`Error during authentication: ${e instanceof Error ? e.message : String(e)}`);
      sessionIdStore.remove();
    }
  }, [isAuth, sessionIdStore, wallet, rangModel, navigate, setError]);

  return {
    initialize,
    sessionId,
    isAuth,
    valueInited,
    userName,
    availableInited,
    clickStep,
    skin,
    rank,
  };
};