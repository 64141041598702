import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "@/shared/lib/hooks/useErrorHandler";
import { useAuth } from "@/features/auth/useAuth";
import { useTelegram } from "@/shared/lib/hooks/useTelegram";
import styles from './Auth.module.scss';

const ANIMATION_TIME = 500;
const REDIRECT_DELAY = ANIMATION_TIME * 7;

export const Auth = () => {
  const [isAnimationEnd, setIsAnimationEnd] = useState(false);
  const [gameName, setGameName] = useState("Loading...");
  const { isValidPlaform } = useTelegram();
  const authModel = useAuth();
  const { errorMessage, setError } = useErrorHandler();

  // Fetch GAME_NAME from backend
  useEffect(() => {
    const fetchGameName = async () => {
      try {
        const response = await fetch("/api/game_data.php");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.GAME_NAME) {
          setGameName(data.GAME_NAME);
        } else {
          throw new Error("GAME_NAME not found in response");
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(`Failed to fetch game name: ${error.message}`);
        } else {
          setError("Failed to fetch game name: Unknown error");
        }
        setGameName("Default Game Name"); // Fallback name
      }
    };

    fetchGameName();
  }, [setError]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimationEnd(true);
      clearTimeout(timeout);
    }, REDIRECT_DELAY);

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (isAnimationEnd && isValidPlaform) {
      authModel.initialize().catch(err => {
        if (err instanceof Error) {
          setError(`Authentication failed: ${err.message}`);
        } else {
          setError("Authentication failed: Unknown error");
        }
      });
    }
  }, [isAnimationEnd, isValidPlaform, authModel, setError]);

  return (
    <div className={styles.root} style={{ backgroundColor: 'black' }}>
      {isValidPlaform && (
        <div className={styles.container}>
          <AnimatedTitle textData={gameName} />
          {errorMessage && (
            <div className={styles['error-message']}>
              <h2>{errorMessage}</h2>
            </div>
          )}
        </div>
      )}
      {!isValidPlaform && (
        <div className={styles['invalid-platform']}>
          <h1>USE MOBILE DEVICE</h1>
          <h1>USE MOBILE DEVICE</h1>
        </div>
      )}
    </div>
  );
};

// Define the interface for the props
interface AnimatedTitleProps {
  textData: string;
}

const AnimatedTitle: React.FC<AnimatedTitleProps> = ({ textData }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const speed = 20; // Adjust the speed if needed

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  return (
    <p className={styles.animatedTitle} aria-label={textData}>
      {textData.split(' ').map((word, i) => (
        <span
          key={`word-${i}`}
          className={`${styles.animatedWord} ${isAnimated ? styles.animate : ''}`}
          data-text={word}
          style={{ opacity: isAnimated ? 1 : 0 }}
        >
          {word.split('').map((letter, j) => {
            const delay = (Math.random() * speed) / 10;
            return (
              <span
                key={`letter-${i}-${j}`}
                className={styles.animatedElement}
                aria-hidden="true"
                style={{ animationDelay: `${delay}s` }}
              >
                {letter === ' ' ? '\u00A0' : letter}
              </span>
            );
          })}
        </span>
      ))}
    </p>
  );
};