import React from 'react';
import { useNavigatorModel, Steps, EarnTabs } from "@/shared/model";
import styles from './ClickerField.module.scss';
import { earnModel } from '@/entities/earn/model';

const BoostBox = () => {
  const { stepChanged, earnTabChanged } = useNavigatorModel();

  const handleBoostClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    earnModel.tasksRequested();
    earnTabChanged(EarnTabs.BOOST);
    stepChanged(Steps.EARN);
  };

  return (
  <div
    className="bg-opacity-100 shadow-lg rounded-md p-4 w-36 h-16 flex flex-col justify-center items-center border border-gray-700 fixed-buttons float-animation"
    style={{
      background: 'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0E 100%)',
      boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.6)',
    }}
    onClick={handleBoostClick}
  >
    <div className="text-xs text-gray-400 font-semibold">Boost</div>
    <div className="text-white text-lg font-bold"> 🚀 </div>
</div>
  );
};

export default BoostBox;