import React, { useMemo } from 'react';
import { EarnItem } from '@/entities/earn/model/types';
import { useTelegram } from '@/shared/lib/hooks/useTelegram';
import { earnModel } from "@/entities/earn/model";

export type TaskExpandModalProps = {
  data: EarnItem | null;
  onClose: () => void;
};

export const TaskExpandModal = React.memo<TaskExpandModalProps>(
  ({ data, onClose }) => {
    const { openLink } = useTelegram();

    // Hooks must be called unconditionally
    const rootClasses = useMemo(() => {
      return `fixed inset-0 flex items-center justify-center z-50`;
    }, []);

    const timeBlocks = useMemo(() => {
      function toView(v: number) {
        return v < 10 ? `0${v}` : `${v}`;
      }

      if (data?.time) {
        const days = Math.floor(data.time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((data.time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((data.time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((data.time % (1000 * 60)) / 1000);

        return {
          days: toView(days),
          hours: toView(hours),
          minutes: toView(minutes),
          seconds: toView(seconds),
        };
      }

      return {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
    }, [data]);

    const handleJoinTask = () => {
      if (data && data.link && data.id && data.amount && data.isDone !== 'done') {
        earnModel.taskJoined(data);
      }
    };

    // Move the null check after Hooks
    if (!data) {
      return null;
    }

    return (
      <div
        className="fixed inset-0 flex items-center justify-center z-50"
        style={{ background: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div
          className="text-white rounded-lg shadow-lg w-80 p-6 text-center"
          style={{
            background:
              'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)',
          }}
        >
          {/* Close Button */}
          <div className="flex justify-end">
            <button
              className="text-gray-400 hover:text-gray-200"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Task Avatar */}
          <div className="flex justify-center mb-4">
            <img
              src={data.avatar}
              alt="Task Avatar"
              className="w-32 h-32 rounded-full"
            />
          </div>

          {/* Title */}
          <h2 className="text-2xl font-semibold mb-2 text-white">
            {data.name}
          </h2>

          {/* Description */}
          <p className="text-gray-300 text-sm mb-4">{data.description}</p>

          {/* Task Details */}
          <div className="space-y-2 mb-6">
            <div className="flex items-center justify-center">
              <span>
                <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
                  Earn:
                </span>{' '}
                <span className="text-white">
                  <strong>{data.amount}</strong>
                </span>
              </span>
            </div>
            <div className="flex items-center justify-center">
              <ul className="list-disc list-inside text-left">
                {data.tasks.map((task, index) => (
                  <li key={index} className="text-gray-300">
                    {task}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Timer */}
          <p className="text-gray-200 text-sm mb-4">
            <span className="bg-gradient-to-r from-[#62cff4] to-[#2c67f2] bg-clip-text text-transparent">
              TIME -{' '}
            </span>
            <span className="text-white">
              {timeBlocks.days}D {timeBlocks.hours}:{timeBlocks.minutes}:
              {timeBlocks.seconds}
            </span>
          </p>

          {/* Join Task Button */}
          <button
            className={`w-full py-2 px-4 rounded-full transition duration-300 ${
              data.isDone === 'done'
                ? 'bg-gray-500 cursor-not-allowed'
                : 'bg-gradient-to-r from-[#62cff4] to-[#2c67f2]'
            }`}
            onClick={handleJoinTask}
            disabled={data.isDone === 'done'}
          >
            {data.isDone === 'done' ? 'Task Completed' : 'Join Task'}
          </button>
        </div>
      </div>
    );
  }
);