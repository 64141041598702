import React, { useCallback, useMemo, useState, useEffect } from "react";
import pointImage from '@/shared/assets/images/main/Point.webp';
import coinImage from '@/shared/assets/images/main/coin.svg';
import skin1 from '@/shared/assets/images/skins/Skin1.webp';
import { useAuth } from "@/features/auth/useAuth";
import BoostBox from './BoostBox';
import { clickerModel } from "../model";
import styles from './ClickerField.module.scss';
import { getRandomArbitrary, getRandomInt, toFormattedNumber } from "@/shared/lib/number";
import { useTelegram } from "@/shared/lib/hooks/useTelegram";
import { useNavigatorModel, Steps, EarnTabs } from "@/shared/model";
import { useUnit } from "effector-react";

const formatNumberForGrid = (num: number): string => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "m";
  } else if (num >= 10000) {
    return (num / 1000).toFixed(1) + "k";
  } else {
    return num.toString();
  }
};

export const ClickerField = () => {
  const { value, available, canBeClicked, onClick } = clickerModel.useClicker();
  const clickStep = useUnit(clickerModel.$clickStep);
  const { haptic } = useTelegram();
  const [isClickEnabled, setIsClickEnabled] = useState(true);
  const skin = clickerModel.$skin.getState();
  const { stepChanged, earnTabChanged } = useNavigatorModel();
  
  const skinImage = useMemo(() => skin || skin1, [skin]);
  const valueString = toFormattedNumber(value);

  const onTouchStart = useCallback(
    (e: TouchEvent) => {
      e.stopPropagation();
      const skinImageElement = e.currentTarget as HTMLImageElement;
      const rect = skinImageElement.getBoundingClientRect();
      const touch = e.touches[0];
      const x = touch.clientX - rect.left - rect.width / 2;
      const y = touch.clientY - rect.top - rect.height / 2;
      skinImageElement.style.setProperty('--rotateX', `${-y / 10}deg`);
      skinImageElement.style.setProperty('--rotateY', `${x / 10}deg`);

      setTimeout(() => {
        skinImageElement.style.setProperty('--rotateX', '0deg');
        skinImageElement.style.setProperty('--rotateY', '0deg');
      }, 100);

      if (isClickEnabled) {
        for (let i = 0; i < Math.min(e.touches.length, 3); i++) {
          const { clientX, clientY } = e.touches[i];
          if (canBeClicked) {
            onClick();

            const point = document.createElement('div');
            point.textContent = `+${clickStep}`;
            point.style.transform = `rotate(${getRandomInt(-25, 25)}deg) scale(${getRandomArbitrary(0.8, 1.2)})`;
            point.className = styles.pointText;

            const pointParent = document.createElement('div');
            pointParent.appendChild(point);
            pointParent.style.top = `${clientY}px`;
            pointParent.style.left = `${clientX}px`;
            pointParent.className = styles.point;

            document.querySelector('#clicker')!.appendChild(pointParent);
            haptic();

            setTimeout(() => {
              if (pointParent && document.querySelector('#clicker')) {
                document.querySelector('#clicker')!.removeChild(pointParent);
              }
            }, 500);
          }
        }

        setIsClickEnabled(false);
        setTimeout(() => {
          setIsClickEnabled(true);
        }, 150);
      }
    },
    [isClickEnabled, canBeClicked, haptic, clickStep, onClick]
  );

  const fontSize = valueString.length > 9 ? '20px' : '24px';

  const handleSkinClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const skinImage = e.currentTarget;
    const rect = skinImage.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;
    skinImage.style.transform = `perspective(1000px) rotateX(${-y / 10}deg) rotateY(${x / 10}deg)`;
    setTimeout(() => {
      skinImage.style.transform = '';
    }, 100);
  };

  function handleTouchMove(event: TouchEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  function handleTouchEnd(event: TouchEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  const handleBoostClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    earnTabChanged(EarnTabs.BOOST);
    stepChanged(Steps.EARN);
  };

  useEffect(() => {
    const skinImageElement = document.getElementById('skinImage');
    if (skinImageElement) {
      skinImageElement.addEventListener('touchstart', onTouchStart as EventListener, { passive: false });
      skinImageElement.addEventListener('touchmove', handleTouchMove as EventListener, { passive: false });
      skinImageElement.addEventListener('touchend', handleTouchEnd as EventListener, { passive: false });
    }
    return () => {
      if (skinImageElement) {
        skinImageElement.removeEventListener('touchstart', onTouchStart as EventListener);
        skinImageElement.removeEventListener('touchmove', handleTouchMove as EventListener);
        skinImageElement.removeEventListener('touchend', handleTouchEnd as EventListener);
      }
    };
  }, [onTouchStart, handleTouchMove, handleTouchEnd]);

  return (
    <div id={'clicker'} className={styles.root}>
      <p className={styles.value} style={{ fontSize }}>
        <img src={coinImage} alt="coin" /> {valueString}
      </p>
      <div className={styles.skinContainer}>
        <img
          key={skinImage}
          id={'skinImage'}
          className={styles.skinImage}
          src={skinImage}
          alt={'skin image'}
          onClick={handleSkinClick}
        />
      </div>

      <div className="flex space-x-4 fixed bottom-28 inset-x-4 fixed-buttons">
        <div className="bg-opacity-100 shadow-lg rounded-md p-4 w-36 h-16 flex flex-col justify-center items-center border border-gray-700 float-animation" style={{ 
          background: 'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0E 100%)', 
          boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.6)' 
        }}>
          <div className="text-xs text-gray-400 font-semibold">Energy</div>
          <div className="text-white text-lg font-bold">{formatNumberForGrid(available)}</div>
        </div>

        <BoostBox />

        <div className="bg-opacity-100 shadow-lg rounded-md p-4 w-36 h-16 flex flex-col justify-center items-center border border-gray-700 float-animation" style={{ 
          background: 'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0E 100%)', 
          boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.6)' 
        }}>
          <div className="text-xs text-gray-400 font-semibold">Damage</div>
          <div className="text-white text-lg font-bold">{formatNumberForGrid(clickStep)}</div>
        </div>
      </div>
    </div>
  );
};