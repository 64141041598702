import { createEvent, createStore } from "effector";
import { useUnit } from "effector-react/effector-react.umd";

export enum Steps {
    HOME = 1,
    FRENS,
    BOARD,
    EARN
}

export enum EarnTabs {
    PARTNER_TASKS = 'Steps.EARN',
    BOOST = 'boost'
}

const stepChanged = createEvent<Steps>();
const earnTabChanged = createEvent<EarnTabs>();

const $step = createStore<Steps>(Steps.HOME)
    .on(stepChanged, (_, payload) => payload);

const $earnTab = createStore<EarnTabs>(EarnTabs.PARTNER_TASKS)
    .on(earnTabChanged, (_, payload) => payload);

export const useNavigatorModel = () => ({
    step: useUnit($step),
    stepChanged: useUnit(stepChanged),
    earnTab: useUnit($earnTab),
    earnTabChanged: useUnit(earnTabChanged)
});